<template>
  <v-container>
    <v-row>
      <s-toolbar label="Historial de conformidad"></s-toolbar>
    </v-row>
    <v-row>
      <v-col class="pt-0 pb-0 pr-0 pl-0">
        <v-card class="mx-auto">
          <v-card-text class="py-0">
            <v-timeline align-top dense>
              <v-timeline-item color="black" small>
                <v-row class="pt-1">
                  <v-col cols="3"
                    ><span class="title-timeline">Etapa</span></v-col
                  >
                  <v-col cols="3"
                    ><span class="title-timeline">Fecha</span></v-col
                  >
                  <v-col cols="3"
                    ><span class="title-timeline"
                      >Tiempo de atención</span
                    ></v-col
                  >
                  <v-col cols="3"
                    ><span class="title-timeline">Usuario</span></v-col
                  >
                </v-row>
              </v-timeline-item>
              <v-timeline-item
                color="pink"
                small
                v-if="this.dataConformity.ReqDate"
              >
                <v-row class="pt-1">
                  <v-col cols="3">
                    <strong>Creación de requerimiento</strong>
                  </v-col>
                  <v-col cols="3">
                    <strong>{{ this.dataConformity.ReqDate }}</strong>
                    <v-icon class="ml-1" color="blue">mdi-calendar</v-icon>
                  </v-col>
                  <v-col cols="3"> </v-col>
                  <v-col cols="3">
                    <strong>{{ this.dataConformity.ReqUsrGen }}</strong>
                  </v-col>
                </v-row>
              </v-timeline-item>

              <v-timeline-item
                color="teal lighten-3"
                small
                v-if="this.dataConformity.ReqAuthDate"
              >
                <v-row class="pt-1">
                  <v-col cols="3">
                    <strong>Aprobación de requerimiento</strong>
                  </v-col>
                  <v-col cols="3">
                    <strong>{{ this.dataConformity.ReqAuthDate }}</strong>
                    <v-icon class="ml-1" color="blue">mdi-calendar</v-icon>
                  </v-col>
                  <v-col cols="3">
                    <strong>
                      {{ this.dataConformity.ReqAttTime }}
                    </strong>
                    <v-icon class="ml-1" color="green"> mdi-clock</v-icon>
                  </v-col>
                  <v-col cols="3">
                    <strong>
                      {{ this.dataConformity.ReqUsrApproval }}
                    </strong>
                  </v-col>
                </v-row>
              </v-timeline-item>

              <v-timeline-item
                color="pink"
                small
                v-if="this.dataConformity.OrdDate"
              >
                <v-row class="pt-1">
                  <v-col cols="3">
                    <strong>Creación de orden</strong>
                  </v-col>
                  <v-col cols="3">
                    <strong>{{ this.dataConformity.OrdDate }}</strong>
                    <v-icon class="ml-1" color="blue">mdi-calendar</v-icon>
                  </v-col>
                  <v-col cols="3">
                    <strong>
                      {{ this.dataConformity.OrdGenTime }}
                    </strong>
                    <v-icon class="ml-1" color="green"> mdi-clock</v-icon>
                  </v-col>
                  <v-col cols="3">
                    <strong>
                      {{ this.dataConformity.OrdUsrGen }}
                    </strong>
                  </v-col>
                </v-row>
              </v-timeline-item>

              <v-timeline-item
                color="teal lighten-3"
                small
                v-if="this.dataConformity.OrdAuthDate"
              >
                <v-row class="pt-1">
                  <v-col cols="3">
                    <strong>Aprobación de orden</strong>
                  </v-col>
                  <v-col cols="3">
                    <strong>{{ this.dataConformity.OrdAuthDate }}</strong>
                    <v-icon class="ml-1" color="blue">mdi-calendar</v-icon>
                  </v-col>
                  <v-col cols="3">
                    <strong> {{ this.dataConformity.OrdAttTime }} </strong>
                    <v-icon class="ml-1" color="green">mdi-clock</v-icon>
                  </v-col>
                  <v-col cols="3">
                    <strong>
                      {{ this.dataConformity.OrdUsrApproval }}
                    </strong>
                  </v-col>
                </v-row>
              </v-timeline-item>
              <v-timeline-item
                color="pink"
                small
                v-if="this.dataConformity.OttAttDate"
              >
                <v-row class="pt-1">
                  <v-col cols="3">
                    <strong>Creacion de conformidad</strong>
                  </v-col>
                  <v-col cols="3">
                    <strong>{{ this.dataConformity.OttAttDate }}</strong>
                    <v-icon class="ml-1" color="blue">mdi-calendar</v-icon>
                  </v-col>
                  <v-col cols="3">
                    <strong>
                      {{ this.dataConformity.OttGenTime }}
                    </strong>
                    <v-icon class="ml-1" color="green"> mdi-clock</v-icon>
                  </v-col>
                  <v-col cols="3">
                    <strong>
                      {{ this.dataConformity.OttUsrGen }}
                    </strong>
                  </v-col>
                </v-row>
              </v-timeline-item>
              <v-timeline-item
                color="teal lighten-3"
                small
                v-if="this.dataConformity.OttAuthDate"
              >
                <v-row class="pt-1">
                  <v-col cols="3">
                    <strong>Aprobación de conformidad</strong>
                  </v-col>
                  <v-col cols="3">
                    <strong>{{ this.dataConformity.OttAuthDate }}</strong>
                    <v-icon class="ml-1" color="blue">mdi-calendar</v-icon>
                  </v-col>
                  <v-col cols="3">
                    <strong> {{ this.dataConformity.OttAttTime }}</strong>
                    <v-icon class="ml-1" color="green">mdi-clock</v-icon>
                  </v-col>
                  <v-col cols="3">
                    <strong>
                      {{ this.dataConformity.OttUsrApproval }}
                    </strong>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import _sRequirement from "@/services/Logistics/RequirementService";
export default {
  name: "RequirementService",
  components: {},
  props: {
    value: {
      default: 1,
      type: Number,
    },
  },
  data: () => ({
    dataConformity: {
      OrdAuthDate: null,
      OrdDate: null,
      OttAttDate: null,
      OttAuthDate: null,
      ReqAuthDate: null,
      ReqDate: null,
      ReqID: null,
      ReqAttTime: null,
      OrdAttTime: null,
      OttAttTime: null,
      OrdGenTime: null,
      OttGenTime: null,
      ReqUsrApproval: null,
      OrdUsrApproval: null,
      OttUsrApproval: null,
      ReqUsrGen: null,
      OrdUsrGen: null,
      OttUsrGen: null,
    },
    dataforDiff: {},
  }),
  methods: {
    formatDate(obj) {
      const onlyDates = (obj1) =>
        Object?.entries(obj1)?.filter(
          ([key, value]) => key?.includes("Date") && value !== null
        );
      const arrayToObject = (array) =>
        array?.reduce(
          (acumulador, [key, value]) => ({
            ...acumulador,
            [`${key}`]: this.moment(value).format(
              this.$const.FormatDateTimeView
            ),
          }),
          {}
        );

      return obj ? arrayToObject(onlyDates(obj)) : {};
    },
    calculeAttentionTime() {
      const diffDate = (date1, date2) => {
        if (date1 === null || date2 === null) return;

        const daysdifference = this.moment(date2).diff(date1, "days");
        if (daysdifference > 0) return `${daysdifference} dias`;

        const hoursdifference = this.moment(date2).diff(date1, "hours");
        if (hoursdifference > 0) return `${hoursdifference} horas`;

        const minutesdifference = this.moment(date2).diff(date1, "minutes");
        if (minutesdifference > 0) return `${minutesdifference} minutos`;

        const secondsdifference = this.moment(date2).diff(date1, "seconds");
        if (secondsdifference > 0) return `${secondsdifference} segundos`;

        return "Inmediato";
      };

      this.dataConformity.ReqAttTime = diffDate(
        this.dataforDiff?.ReqDate,
        this.dataforDiff?.ReqAuthDate
      );
      this.dataConformity.OrdGenTime = diffDate(
        this.dataforDiff?.ReqAuthDate,
        this.dataforDiff?.OrdDate
      );
      this.dataConformity.OrdAttTime = diffDate(
        this.dataforDiff?.OrdDate,
        this.dataforDiff?.OrdAuthDate
      );
      this.dataConformity.OttGenTime = diffDate(
        this.dataforDiff?.OrdAuthDate,
        this.dataforDiff?.OttAttDate
      );
      this.dataConformity.OttAttTime = diffDate(
        this.dataforDiff?.OttAttDate,
        this.dataforDiff?.OttAuthDate
      );
    },
  },
  watch: {},
  created() {},
  mounted() {
    _sRequirement
      .conformityhistory({ ReqID: this.value }, this.$fun.getUserID())
      .then((rpta) => {
        const { data } = rpta;
        this.dataforDiff = { ...data };
        this.dataConformity = {
          ...this.dataConformity,
          ...data,
          ...this.formatDate(data),
        };
      })
      .then(() => {
        this.calculeAttentionTime();
      })
      .catch((error) => console.log(error));
  },
};
</script>
<style scoped>
.title-timeline {
  color: #4076af;
  font-weight: bold;
}
</style>
